<template>
  <div class="sidebar">
    <div class="container">
      <div class="row">
        <div class="col col-sm-24">
          <a href="tel:2034415779">
            <img src="@/assets/phone-blue.png">
            <h1>Call Us</h1>
          </a>
        </div>
        <div class="col col-sm-24">
          <a href="mailto:support@sharemyaircraft.com">
            <img src="@/assets/mail-blue.png">
            <h1>Email Us</h1>
          </a>
        </div>
        <div class="col col-sm-24">
          <router-link :to=" { name: 'faq' }">
            <img src="@/assets/help-blue.png">
            <h1>FAQ's</h1>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SmaSidebar'
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: none;

  @media (min-width: $breakpoint-sm) {
    display: block;
  }

  .col {
    border-bottom: 1px $sma-light-gray solid;
    padding: 1rem 0;
    max-width: 220px;

    &:last-child {
      border: none;
    }

    & a {
      display: flex;
      align-items: center;
      justify-content: left;
    }
  }

  & img {
    max-height: 48px;
    max-width: 48px;
    margin: 0 0.8em 0 0;
  }

  & h1 {
    font-size: 1.2em;
    color: $sma-blue;
    font-weight: 500;
  }
}
</style>
