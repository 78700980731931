<template>
  <div class="manage-account-page">
    <div class="container">
      <div class="row">
        <sma-account-sidebar class="col col-sm-5" />

        <div class="col col-sm-19">
          <h2>Manage Subscription</h2>

          <div class="instructions">
            The first charge will appear on your card today, and subscriptions automatically renew the same day each month. You can come back to this account area to post new planes, favorite listings, set new listing alerts, update your pilot profile, and manage your subscription.
            <br>
            <br>
            Any questions, please email us at <a href="mailto:support@sharemyaircraft.com">support@sharemyaircraft.com</a>.
          </div>

          <!-- Subscription Options -->
          <div class="row sub-options">
            <!-- Subscription Option -->
            <div
              v-for="subOption of subOptions"
              :key="subOption.priceId"
              class="col col-sm-12"
            >
              <div class="column">
                <!-- Title -->
                <h3>{{ subOption.title }}</h3>

                <!-- Description -->
                <div class="desc">
                  {{ subOption.desc }}
                </div>

                <!-- Exp Date -->
                <div
                  v-if="me.subscriptions[subOption.priceId].isActive"
                  class="exp-msg"
                >
                  <div
                    v-if="me.subscriptions[subOption.priceId].isTrial"
                  >
                    <strong>Trial Ends On:</strong> {{ me.subscriptions[subOption.priceId].trialExpDate }}
                  </div>
                  <div
                    v-else
                  >
                    <strong>
                      Subscription {{ me.subscriptions[subOption.priceId].isCanceled ? 'Expires' : 'Renews' }} On:
                    </strong>
                    {{ me.subscriptions[subOption.priceId].expDate }}
                  </div>

                  <div
                    v-if="me.subscriptions[subOption.priceId].discount"
                  >
                    <strong>Coupon Applied:</strong> {{ me.subscriptions[subOption.priceId].discount }}
                  </div>
                </div>

                <div class="spacer" />

                <!-- Subscribe Button -->
                <div class="sub-btns">
                  <button
                    v-if="me.subscriptions[subOption.priceId].isActive
                      && !me.subscriptions[subOption.priceId].isCanceled"
                    class="btn red"
                    @click="onUnsubClick(subOption)"
                  >
                    Cancel subscription
                  </button>
                  <button
                    v-else
                    class="btn"
                    @click="onSubClick(subOption)"
                  >
                    {{ me.subscriptions[subOption.priceId].isCanceled && me.subscriptions[subOption.priceId].isActive ? 'Restart Subscription' : 'Subscribe' }}
                  </button>
                </div>

              <!-- Instructor Account -->
              <!-- <div
                v-if="subOption.type === 'instructor' && isSubscribedButNotInstructor"
                class="instructor-acct"
              >
                <div class="text">
                  Now that you’ve purchased your instructor subscription, fill out your profile here!
                </div>
                <div class="btn-container">
                  <button
                    class="btn"
                    @click="onInstructorClick()"
                  >
                    Instructor Details
                  </button>
                </div>
              </div> -->
              </div>
            </div>
          </div>

          <!-- Subscription Options -->
          <div class="row">
            <!-- Subscription Option -->
            <div
              v-for="subOption of subOptions"
              :key="subOption.priceId"
              class="col col-sm-12"
            >
              <!-- Instructor Account -->
              <div
                v-if="subOption.type === 'instructor' && isSubscribedButNotInstructor"
                class="instructor-acct"
              >
                <div class="text">
                  Now that you’ve purchased your instructor subscription, fill out your profile here!
                </div>
                <div class="btn-container">
                  <button
                    class="btn"
                    @click="onInstructorClick()"
                  >
                    Instructor Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SmaAccountSidebar from '@/components/AccountSidebar';

import {
  SHOW_MODAL,
  UPDATE_SUBSCRIPTION
} from '@/store/action-types';

export default {
  name: 'SmaManageSubscription',

  components: {
    SmaAccountSidebar
  },

  data() {
    return {
      subOptions: [
        {
          type: 'list',
          priceId: process.env.LIST_SUBSCRIPTION_PRICE_ID,
          title: 'Share Subscription',
          desc: 'Connect with local, highly qualified pilots looking for a plane just like yours. Post a share in minutes. Your first month is free, $12/month after that. Start making money with your plane today.'
        }
        // {
        //   type: 'instructor',
        //   priceId: process.env.INSTRUCTOR_SUBSCRIPTION_PRICE_ID,
        //   title: 'Instructor Subscription',
        //   desc: 'Join our growing database of flight instructors. All specialties are welcome. Post a profile and get discoverable in minutes. Your first month is free, $12/month after that. Get exposure today.'
        // }
      ],
      isCanceling: false
    };
  },

  computed: {
    ...mapGetters(['me']),

    isSubscribedButNotInstructor() {
      return !this.me.isInstructor && this.me.subscriptions[process.env.INSTRUCTOR_SUBSCRIPTION_PRICE_ID].isActive;
    }
  },

  methods: {
    /*
     * Handlers
     */

    async onSubClick(subOption) {
      if (this.me.subscriptions[subOption.priceId].isCanceled && this.me.subscriptions[subOption.priceId].isActive) {
        await this.$store.dispatch(UPDATE_SUBSCRIPTION, {
          subscriptionId: this.me.subscriptions[subOption.priceId].id,
          cancelAtPeriodEnd: false
        });
        return;
      }

      await this.$store.dispatch(SHOW_MODAL, { name: 'subscribe', subType: subOption.type });
    },

    async onUnsubClick(sub) {
      if (this.isCanceling) { return; }
      this.isCanceling = true;

      try {
        await this.$store.dispatch(UPDATE_SUBSCRIPTION, {
          subscriptionId: this.me.subscriptions[sub.priceId].id,
          cancelAtPeriodEnd: true
        });
      } finally {
        this.isCanceling = false;
      }
    },

    onInstructorClick() {
      this.$store.dispatch(SHOW_MODAL, {
        name: 'instructor-signup'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 32px;

  &.row {
    margin-bottom: 7rem;
  }
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
}

.tabs {
  .button {
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    background-color: $sma-yellow;
    color: $sma-white;
    border: 1px solid $sma-white;

    &.inactive {
      background-color: $sma-white;
      color: $sma-yellow;
      border: 1px solid $sma-yellow;
    }
  }
}

.sub-options {
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.spacer {
  flex: 1 1 auto;
}

.col.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.del-button {
  background-color: $sma-red;
  margin-left: 2em;
}

h2 {
  text-align: center;
}

.btn-container {
  text-align: center;
}

.instructions {
  margin-bottom: 16px;
}

.desc {
  margin-bottom: 8px;
}

.exp-msg {
  margin-bottom: 16px;
}

.sub-btns {
  text-align: center;
}

.instructor-acct {
  margin-top: 16px;

  .text {
    margin-bottom: 8px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }
}
</style>
