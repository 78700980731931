<template>
  <div
    v-if="activeModalName === 'instructor'"
    class="instructor-modal modal-background"
    @click="hide"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>
      <div class="content container">
        <div class="row">
          <div class="col col-sm-8">
            <div
              class="image aspect-ratio-1-1"
              :style="getBackgroundStyle()"
            />
          </div>
          <div class="col col-sm-16">
            <h2>{{ instructor.first }} {{ instructor.last }}</h2>
            <div>${{ instructor.ratePerHour }} per hour</div>
            <div class="buttons">
              <div><a
                class="button email"
                :href="mailto"
              >
                <span class="icon-container"><fa-icon
                  icon="envelope"
                  class="icon"
                /></span> Send Email
              </a></div>
              <div><button
                class="button email"
                @click="copyEmailToClipboard"
              >
                <span class="icon-container"><fa-icon
                  icon="clipboard"
                  class="icon"
                /></span> Copy Email to Clipboard
              </button></div>
              <div v-if="me">
                <button
                  v-if="!isFavorited"
                  class="button favorite"
                  @click="addFavorite"
                >
                  <span class="icon-container"><fa-icon
                    icon="heart"
                    class="icon"
                  /></span> Add to Favorites
                </button>
                <button
                  v-else
                  class="button favorite"
                  @click="removeFavorite"
                >
                  <span class="icon-container"><fa-icon
                    icon="ban"
                    class="icon"
                  /></span> Remove from Favorites
                </button>
              </div>
            </div>
            <div class="about-me">
              {{ instructor.about }}
            </div>
          </div>
          <div class="col">
            <h3>Instructor Details</h3>
          </div>
          <div class="col col-sm-12">
            <div class="pair">
              <div class="name">
                Airport:
              </div>
              <div class="value">
                {{ instructor.airport ? instructor.airport.locationId : '' }}
              </div>
            </div>
          </div>
          <div class="col col-sm-12">
            <div class="pair">
              <div class="name">
                City:
              </div>
              <div class="value">
                {{ instructor.airport ? instructor.airport.city : '' }}
              </div>
            </div>
          </div>
          <div class="col col-sm-12">
            <div class="pair">
              <div class="name">
                State:
              </div>
              <div class="value">
                {{ instructor.airport ? instructor.airport.state : '' }}
              </div>
            </div>
          </div>
          <div class="col col-sm-12">
            <div class="pair">
              <div class="name">
                Specialties:
              </div>
              <div class="value">
                {{ instructor.tags ? instructor.tags.join(', ') : '' }}
              </div>
            </div>
          </div>
          <div
            v-if="instructor.businessName"
            class="col col-sm-12"
          >
            <div class="pair">
              <div class="name">
                Business:
              </div>
              <div class="value">
                {{ instructor.businessName }}
              </div>
            </div>
          </div>
          <div
            v-if="instructor.bio"
            class="col col-sm-24"
          >
            <div class="pair">
              <div class="name">
                Bio:
              </div>
              <div class="value">
                {{ instructor.bio }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ADD_FAVORITE_INSTRUCTOR, REMOVE_FAVORITE_INSTRUCTOR, HIDE_MODAL } from '@/store/action-types';
import getBackgroundStyle from '@/utils/get-background-style';

export default {
  name: 'SmaInstructorModal',

  computed: {
    ...mapGetters([
      'activeModal',
      'activeModalName',
      'getInstructorById',
      'error',
      'me'
    ]),

    instructor() {
      return this.getInstructorById(this.activeModal.instructorId);
    },

    isFavorited() {
      const faves = this.$store.getters.favoriteInstructors;

      return !!faves.find(item => item.id === this.instructor.id);
    },

    mailto() {
      if (!this.instructor || !this.instructor.email) {
        return '#';
      }

      return `mailto:${this.instructor.email}?subject=Share inquiry from sharemyaircraft.com`;
    }
  },

  methods: {
    hide() {
      this.email = '';
      this.$store.dispatch(HIDE_MODAL);
    },

    getBackgroundStyle() {
      // const media = this.instructor && this.instructor.media;
      // const url = media && media[0] && media[0].url;
      const url = this.instructor && this.instructor.avatar && this.instructor.avatar.url;

      return {
        ...getBackgroundStyle(url),
        width: '100%'
      };
    },

    addFavorite() {
      this.$store.dispatch(ADD_FAVORITE_INSTRUCTOR, this.instructor);
    },

    removeFavorite() {
      this.$store.dispatch(REMOVE_FAVORITE_INSTRUCTOR, this.instructor);
    },

    copyEmailToClipboard() {
      if (!this.instructor || !this.instructor.email) {
        return;
      }

      const textArea = document.createElement('textarea');

      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';

      textArea.value = this.instructor.email;

      document.body.appendChild(textArea);

      textArea.focus();
      textArea.select();

      document.execCommand('copy');

      document.body.removeChild(textArea);
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: $sma-white;
}

.content {
  padding: 2rem;
}

.close {
  float: right;
  color: $sma-gray;
  font-size: 1.5rem;
}

.image {
  background-color: $sma-near-white;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
  margin: 1rem 0;

  button,
  a {
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 400;
    color: $sma-blue;
    border: 0;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .icon-container {
    display: inline-flex;
    justify-content: center;
    align-items: baseline;
    width: 1.4em;
    height: 100%;
    margin-right: 0.2em;
  }
}

.about-me {
  font-size: 0.875rem;
}

.pair {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  flex-wrap: wrap;
}

.name {
  font-weight: 600;
  margin-right: 0.5em;
  min-width: 100px;
  text-align: right;
}

.value {
  flex: 1;
  color: $sma-gray;

  & input {
    width: 100%;
    border-bottom: 1px solid $sma-gray;
    color: $sma-gray;
  }
}
</style>
