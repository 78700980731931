<template>
  <div
    v-if="activeModalName === 'subscribe-notification'"
    class="subscribe-modal modal-background"
    @click="hide"
  >
    <div
      class="modal"
      @click.stop
    >
      <button
        class="close-btn"
        @click="hide"
      >
        <fa-icon icon="times" />
      </button>

      <div class="starburst-container">
        <div class="starburst">
          <img
            class="bg"
            src="@/assets/starburst.svg"
          >
          <div
            class="text"
            :class="[activeModal.subType]"
          >
            <div class="h1">
              SMA
            </div>

            <div class="h2">
              {{ subTitle }}
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="h1">
          Sign-Up Now!
        </div>

        <div class="h2">
          {{ subCallout }}
        </div>

        <div class="body">
          {{ subDesc }}
        </div>

        <div class="body">
          {{ subDesc2 }}
        </div>

        <div class="body">
          {{ subDesc3 }}
        </div>

        <div class="text">
          {{ subTypeText }}
        </div>

        <div class="btns">
          <button
            class="btn"
            @click="onSubscribeBtnClicked"
          >
            Subscribe
          </button>
          <button
            class="btn"
            @click="onCancelBtnClicked"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  HIDE_MODAL
} from '@/store/action-types';

export default {
  name: 'SmaSubscribeNotificationModal',

  computed: {
    ...mapGetters([
      'me',
      'activeModalName',
      'activeModal'
    ]),

    subTitle() {
      switch ((this.activeModal || {}).subType) {
        case 'list':
          return 'LISTINGS';

        case 'instructor':
          return 'INSTRUCTORS';

        default:
          return '';
      }
    },

    subCallout() {
      switch ((this.activeModal || {}).subType) {
        case 'list':
          return 'READY TO MAKE MONEY WITH YOUR AIRCRAFT?';

        case 'instructor':
          return 'Start instructing';

        default:
          return '';
      }
    },

    subDesc() {
      switch ((this.activeModal || {}).subType) {
        case 'list':
          return 'Post your first share in minutes and find the perfect partner to fly and share your plane - under your terms - without impacting your flying at all.';

        case 'instructor':
          return 'Just $12 per month to be an instructor! Cancel at any time.';

        default:
          return '';
      }
    },

    subDesc2() {
      switch ((this.activeModal || {}).subType) {
        case 'list':
          return 'Cover all those annoying expenses, like your hangar, SW subscriptions and that dreaded annual, and meet other like-minded highly qualified pilots at your local airport! It’s a win-win-win!';

        case 'instructor':
          return 'Just $12 per month to be an instructor! Cancel at any time.';

        default:
          return '';
      }
    },

    subDesc3() {
      switch ((this.activeModal || {}).subType) {
        case 'list':
          return 'Every Month you will get a detailed report from us as to how many pilots viewed your listing and other information and you can work with us to make your listing the best that it can be!';

        case 'instructor':
          return 'Just $12 per month to be an instructor! Cancel at any time.';

        default:
          return '';
      }
    },

    subTypeText() {
      switch ((this.activeModal || {}).subType) {
        case 'list':
          return 'Just $12 per month to post unlimited shares and you can cancel at any time.';

        case 'instructor':
          return 'You need a subscription to become an instructor';

        default:
          return '';
      }
    }
  },

  methods: {
    hide() {
      this.$store.dispatch(HIDE_MODAL);
    },

    /*
     * Handlers
     */

    onSubscribeBtnClicked() {
      this.$router.push({ name: 'manage-subscription' });
      this.hide();
    },

    onCancelBtnClicked() {
      this.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  width: 500px;
  overflow: visible !important;
  margin-top: 75px !important;
}

.starburst-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.starburst {
  position: absolute;
  top: -75px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;

  .bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .text {
    z-index: 2;
    text-align: center;
    color: $sma-white;
    font-weight: 500;
    border: 1px dashed $sma-white;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    height: 80%;

    .h1 {
      font-size: 14px;
    }

    .h2 {
      font-size: 20px;
    }

    &.instructor {
      .h1 {
        font-size: 14px;
      }

      .h2 {
        font-size: 14px;
      }
    }
  }
}

.content {
  padding-top: 100px !important;
  text-align: center;

  .text {
    margin-bottom: 24px;
  }

  .input {
    height: 38px;
    background: $sma-white;
    border: 1px solid $sma-light-gray;
    border-radius: 5px;
    padding: 8px;
  }

  .h1,
  .h2 {
    color: $sma-blue;
    font-weight: 600;
  }

  .h1 {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .h2 {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .body {
    margin-bottom: 1rem;
    color: $sma-blue;
    font-weight: 600;
  }

  .ccs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .cc {
      width: 42px;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .error-msg {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    color: $sma-red;
  }

  .footnote {
    font-size: 12px;
    margin-bottom: 1rem;
  }
}

.button {
  display: block;
  cursor: pointer;
  padding: 0.5rem 4rem;
  margin: 0.5rem auto;
  border: 1px solid $sma-yellow;
  color: $sma-yellow;
  border-radius: 10rem;
  white-space: nowrap;
  text-align: center;
}
</style>
