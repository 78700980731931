<template>
  <div class="terms-page info-page">
    <div class="container">
      <h1>TERMS OF SERVICE</h1>

      <h2>1. Your Acceptance</h2>

      <p>
        (a) By using or visiting the ShareMyAircraft.com website or any
        ShareMyAircraft.com products, software, data feeds, information, forms
        and/or services provided to you on, from, or through the
        ShareMyAircraft.com website (collectively the "Service") you signify
        your agreement to (1) these terms and conditions (the "Terms of
        Service"), (2) ShareMyAircraft.com's privacy notice, found at
        http://www.ShareMyAircraft.com/privacy and incorporated herein by
        reference, and (3) ShareMyAircraft.com's Guidelines, found at
        http://www.ShareMyAircraft.com/about and also incorporated herein by
        reference. If you do not agree to any of these terms, the
        ShareMyAircraft.com privacy notice, or the Community Guidelines, please
        do not use the Service.
      </p>

      <p>
        (b) Although we may attempt to notify you when major changes are made to
        these Terms of Service, you should periodically review the most
        up-to-date version at http://www.ShareMyAircraft.com/terms.
        ShareMyAircraft.com may, in its sole discretion, modify or revise these
        Terms of Service and policies at any time, and you agree to be bound by
        such modifications or revisions. Nothing in these Terms of Service shall
        be deemed to confer any third-party rights or benefits.
      </p>

      <h2>2. The Service</h2>

      <p>
        (a) These Terms of Service apply to all users of the Service, including
        users who are also contributors of Content on the Service. "Content"
        includes the text, software, scripts, graphics, photos, sounds, music,
        videos, forms, documents, agreements, audiovisual combinations,
        interactive features and other materials you may view on, access
        through, or contribute to the Service. The Service includes all aspects
        of ShareMyAircraft.com, including but not limited to all products,
        software and services offered via the ShareMyAircraft.com website, such
        as the ShareMyAircraft.com channels, the ShareMyAircraft.com "Embeddable
        Player," the ShareMyAircraft.com "Uploader" and other applications.
      </p>

      <p>
        (b) The Service may contain links to third party websites that are not
        owned or controlled by ShareMyAircraft.com. ShareMyAircraft.com has no
        control over, and assumes no responsibility for, the content, privacy
        policies, or practices of any third party websites. In addition,
        ShareMyAircraft.com will not and cannot censor or edit the content of
        any third-party site. By using the Service, you expressly relieve
        ShareMyAircraft.com from any and all liability arising from your use of
        any third-party website.
      </p>

      <p>
        Accordingly, we encourage you to be aware when you leave the Service and
        to read the terms and conditions and privacy policy of each other
        website that you visit.
      </p>

      <h2>3. Convenience and Information Only</h2>

      <p>
        The Service is currently provided to you as a convenience and for your
        information only. By merely providing access to the Service,
        ShareMyAircraft.com does not represent or warrant that: (a) the Content
        is accurate or complete; (b) the Content is up-to-date or current; (c)
        ShareMyAircraft.com has any obligation to update any Content; (d) the
        Content is free from technical inaccuracies or typographical errors; (e)
        the Content is free from changes caused by a third party; (f) your
        access to the Service will be free from interruptions, errors, computer
        viruses or other harmful components; and/or (g) any information obtained
        in response to questions asked through the Service is or will be
        accurate or complete.
      </p>

      <h2>4. ShareMyAircraft.com Accounts</h2>

      <p>
        In order to access some features of the Service, you will have to create
        a ShareMyAircraft.com account. You may never use another's account
        without permission. When creating your account, you must provide
        accurate and complete information. You are solely responsible for the
        activity that occurs on your account, and you must keep your account
        password secure. You agree to exit from your account at the end of each
        session. You must notify ShareMyAircraft.com immediately of any breach
        of security or unauthorized use of your account.
      </p>

      <p>
        Although ShareMyAircraft.com will not be liable for your losses caused
        by any unauthorized use of your account, you may be liable for the
        losses of ShareMyAircraft.com or others due to such unauthorized use.
      </p>

      <p>
        While we provide certain encryption technologies and use other
        reasonable precautions to protect your confidential information and
        provide suitable security, we do not and cannot guarantee or warrant
        that information transmitted through the internet is secure, or that
        such transmissions are free from delay, interruption, interception or
        error.
      </p>

      <h2>5. General Use of the Service—Permissions and Restrictions</h2>

      <p>
        (a) ShareMyAircraft.com hereby grants you permission to access and use
        the Service as set forth in these Terms of Service, provided that:
      </p>
      <ul>
        <li>
          You agree not to distribute in any medium any part of the Service or
          the Content without ShareMyAircraft.com's prior written authorization,
          unless ShareMyAircraft.com makes available the means for such
          distribution through functionality offered by the Service (such as the
          Embeddable Player).
        </li>

        <li>You agree not to change or modify any part of the Service.</li>

        <li>
          You agree not to access any Content through any technology or means
          other than the video playback pages of the Service itself, the
          Embeddable Player, or other explicitly authorized means that
          ShareMyAircraft.com may designate.
        </li>

        <li>
          You agree not to use the Service for any of the following commercial
          uses unless you obtain ShareMyAircraft.com's prior written approval:
          <ul>
            <li>the sale of access to the Service;</li>

            <li>
              the sale of advertising, sponsorships, or promotions placed on
              or within the Service or Content; or
            </li>

            <li>
              the sale of advertising, sponsorships, or promotions on any page
              of an ad-enabled blog or website containing Content delivered via
              the Service, unless other material not obtained from
              ShareMyAircraft.com appears on the same page and is of sufficient
              value to be the basis for such sales.
            </li>
          </ul>
        </li>
      </ul>
      <p>(b) Prohibited commercial uses do not include:</p>
      <ul>
        <li>
          uploading an original video or photos to ShareMyAircraft.com, or
          maintaining an original channel on ShareMyAircraft.com, to promote
          your business;
        </li>

        <li>
          showing ShareMyAircraft.com videos through the Embeddable Player on
          an ad-enabled blog or website, subject to the advertising restrictions
          set forth above; or
        </li>

        <li>
          any use that ShareMyAircraft.com expressly authorizes in writing.
        </li>
      </ul>
      <p>
        (c) If you use the Embeddable Player on your website, you may not
        modify, build upon, or block any portion or functionality of the
        Embeddable Player, including but not limited to links back to the
        ShareMyAircraft.com website.
      </p>

      <p>
        (d) If you use the ShareMyAircraft.com Uploader, you agree that it may
        automatically download and install updates from time to time from
        ShareMyAircraft.com. These updates are designed to improve, enhance and
        further develop the Uploader and may take the form of bug fixes,
        enhanced functions, new software modules and completely new versions.
        You agree to receive such updates (and permit ShareMyAircraft.com to
        deliver these to you) as part of your use of the Uploader.
      </p>

      <p>
        (e) You agree not to use or launch any automated system, including, but
        not limited to, "robots," "spiders," or "offline readers," that accesses
        the Service in a manner that sends more request messages to the
        ShareMyAircraft.com servers in a given period of time than a human can
        reasonably produce in the same period by using a conventional on-line
        web browser. Notwithstanding the foregoing, ShareMyAircraft.com grants
        the operators of public search engines permission to use spiders to copy
        materials from the site for the sole purpose of and solely to the extent
        necessary for creating publicly available searchable indices of the
        materials, but not caches or archives of such materials.
        ShareMyAircraft.com reserves the right to revoke these exceptions either
        generally or in specific cases. You agree not to collect or harvest any
        personally identifiable information, including account names, from the
        Service, nor to use the communication systems provided by the Service
        (e.g., comments, email) for any commercial solicitation purposes. You
        agree not to solicit, for commercial purposes, any users of the Service
        with respect to their Content.
      </p>

      <p>
        (f) In your use of the Service, you will comply with all applicable
        laws.
      </p>

      <p>
        (g) ShareMyAircraft.com reserves the right to discontinue any aspect of
        the Service at any time.
      </p>

      <h2>6. Your Use of Content on The Site</h2>

      <p>
        In addition to the general restrictions above, the following
        restrictions and conditions apply specifically to your use of our
        Content:
      </p>

      <p>
        (a) The Content on the Service, and the trademarks, service marks and
        logos (collectively, "Marks") on the Service, are owned by or licensed
        to ShareMyAircraft.com, subject to copyright and other intellectual
        property rights under the law.
      </p>

      <p>
        (b) The Content is provided to you "AS IS", "AS AVAILABLE". You may
        access Content for your information and personal use solely as intended
        through the provided functionality of the Service and as permitted under
        these Terms of Service. You shall not download any Content unless you
        see a "download" or similar link displayed by ShareMyAircraft.com on the
        Service for that Content. You shall not copy, reproduce, distribute,
        transmit, broadcast, display, sell, license, or otherwise exploit any
        Content for any other purposes without the prior written consent of
        ShareMyAircraft.com or the respective licensors of the Content.
        ShareMyAircraft.com and its licensors reserve all rights not expressly
        granted in and to the Service and the Content. At any time,
        ShareMyAircraft.com may, without further notice, make changes to the
        Service, without prior notice to you.
      </p>

      <p>
        (c) You agree not to circumvent, disable or otherwise interfere with any
        security-related features of the Service or any features that prevent or
        restrict use or copying of any Content or enforce limitations on use of
        the Service or the Content therein.
      </p>

      <p>
        (d) You understand that, when using the Service, you will be exposed to
        Content from a variety of sources, and that ShareMyAircraft.com is not
        responsible for the accuracy, usefulness, safety, or intellectual
        property rights of or relating to any such Content. You further
        understand and acknowledge that you may be exposed to Content that is
        inaccurate, offensive, indecent, or objectionable, and you agree to
        waive, and hereby do waive, any legal or equitable rights or remedies
        you have or may have against ShareMyAircraft.com with respect thereto,
        and, to the extent permitted by applicable law, agree to indemnify and
        hold harmless ShareMyAircraft.com, its owners, operators, affiliates,
        licensors, and licensees to the fullest extent allowed by law regarding
        all matters related to your use of, or inability to use, the Service.
      </p>

      <h2>7. Your Content and Conduct</h2>

      <p>
        (a) As a ShareMyAircraft.com account holder, you may submit Content to
        the Service, including videos and user comments. You understand that
        ShareMyAircraft.com does not guarantee any confidentiality with respect
        to any Content that you submit.
      </p>

      <p>
        (b) You shall be solely responsible for your own Content and the
        consequences of submitting and publishing your Content on the Service.
        You affirm, represent, and warrant that you own or have the necessary
        licenses, rights, consents, and permissions to publish any Content that
        you submit. You license to ShareMyAircraft.com all patent, trademark,
        trade secret, copyright or other proprietary rights in and to such
        Content for publication on the Service pursuant to these Terms of
        Service.
      </p>

      <p>
        (c) For clarity, you retain all of your ownership rights in your
        Content. However, by submitting Content to ShareMyAircraft.com, you
        hereby grant ShareMyAircraft.com a worldwide, non-exclusive,
        royalty-free, sublicenseable and transferable license to use, reproduce,
        distribute, prepare derivative works of, display, and perform the
        Content in connection with the Service and ShareMyAircraft.com's (and
        its successors' and affiliates') business, including, but not limited
        to, for promoting and redistributing part or all of the Service (and
        derivative works thereof) in any media formats and through any media
        channels, whether now known or hereafter discovered or developed. You
        also hereby grant each user of the Service a non-exclusive license to
        access your Content through the Service, and to use, reproduce,
        distribute, display and perform such Content as permitted through the
        functionality of the Service and under these Terms of Service. The above
        licenses granted by you with respect to any video Content that you
        submit to the Service automatically terminate within a commercially
        reasonable time after you remove or delete your videos from the Service.
        You understand and agree, however, that ShareMyAircraft.com may retain,
        but not display, distribute, or perform, server copies of your videos
        that have been removed or deleted. The above licenses granted by you in
        user comments you submit are perpetual and irrevocable.
      </p>

      <p>
        (d) You further agree that any Content that you submit to the Service
        will not contain any third party copyrighted material, or any material
        that is subject to other third party proprietary rights, unless you have
        permission from the rightful owner of the material or you are otherwise
        legally entitled to post the material and to grant ShareMyAircraft.com
        all of the license rights granted herein.
      </p>

      <p>
        (e) You further agree that you will not submit to the Service any
        Content or other material that is contrary to the ShareMyAircraft.com
        Community Guidelines, found at http://www.ShareMyAircraft.com/guidelines
        which may be updated from time to time, or contrary to applicable local,
        national, and international laws and regulations.
      </p>

      <p>
        (f) ShareMyAircraft.com does not endorse any Content submitted to the
        Service by any user or other licensor, or any opinion, recommendation,
        or advice expressed therein, and ShareMyAircraft.com expressly disclaims
        any and all liability in connection with Content. ShareMyAircraft.com
        does not permit copyright infringing activities and infringement of
        intellectual property rights on or through the Service, and
        ShareMyAircraft.com will remove all Content if properly notified that
        such Content infringes on another's intellectual property rights.
        ShareMyAircraft.com reserves the right to remove Content without prior
        notice.
      </p>

      <p>
        (g) ShareMyAircraft.com seeks Content that demonstrates behaviors or
        patterns of behaviors of individuals or entities. It is not our intent
        to present Content in a manner that intentionally presents information
        or statements out of context. You agree to use reasonable judgment when
        submitting Content to ensure that the Content complies with this
        intention.
      </p>

      <h2>8. Legal Forms</h2>

      <p>
        ShareMyAircraft.com may offer various legal forms on or through the
        Service (each, a "Legal Form"). If you obtain one or more Legal Forms
        from ShareMyAircraft.com, then the following terms apply to you:
      </p>

      <p>
        (a) License. ShareMyAircraft.com grants you a limited, nonexclusive,
        nontransferable worldwide right and license to use the Legal Form(s)
        that you obtain on or through the Service. This license allows you to
        access, download, use, and edit the Legal Form(s) you obtain for your
        personal use. You may not: (i) license, sublicense, sell, resell,
        transfer, assign, distribute, or otherwise make available to any third
        party any such Legal Form; or (ii) modify, copy, reproduce, reverse
        engineer, or make derivative works based on, referring to, or exploiting
        any Legal Form(s) or their source code, except for modifications to the
        forms for your personal use. All rights not expressly granted to you are
        reserved by ShareMyAircraft.com and its licensors.
      </p>

      <p>
        (b) No Guarantee. ShareMyAircraft.com does not promise or guarantee that
        any Legal Form provided on or through the Service is suitable for any
        particular purpose, or that any such Legal Form is accurate, reliable,
        complete, timely or enforceable in your jurisdiction.
      </p>

      <p>
        (c) Disclaimer. You understand and agree that ShareMyAircraft.com is not
        a law firm or an attorney, may not perform services performed by an
        attorney, and its Legal Forms are not a substitute for the advice or
        services of an attorney. Any Legal Form(s) obtained from
        ShareMyAircraft.com do not, and are not intended to, constitute legal
        advice, nor are they intended as a source of advertising or
        solicitation. Your use of the Service or any of our Legal Forms does not
        create or constitute an attorney-client relationship. You should not
        consider these materials to be an invitation for an attorney-client
        relationship, you should not rely on any information or Legal Forms
        obtained on or through the Service without first obtaining separate
        legal advice, and you should always seek the advice of competent legal
        counsel in your own jurisdiction. The Service should not be viewed as an
        offer to perform legal services for you. DO NOT send ShareMyAircraft.com
        any information concerning your legal situation.
      </p>

      <h2>9. Account Termination Policy</h2>

      <p>
        (a) ShareMyAircraft.com will terminate a user's access to the Service
        if, under appropriate circumstances, the user is determined to be a
        repeat infringer.
      </p>

      <p>
        (b) ShareMyAircraft.com reserves the right to decide whether any Content
        violates these Terms of Service for reasons other than copyright
        infringement, such as, but not limited to, pornography, obscenity, or
        excessive length. ShareMyAircraft.com may at any time, without prior
        notice and in its sole discretion, remove such Content and/or terminate
        a user's account for submitting such material in violation of these
        Terms of Service.
      </p>

      <h2>10. Digital Millennium Copyright Act</h2>

      <p>
        (a) If you are a copyright owner or an agent thereof and believe that
        any Content infringes upon your copyrights, you may submit a
        notification pursuant to the Digital Millennium Copyright Act ("DMCA")
        by providing our Copyright Agent with the following information in
        writing (see 17 U.S.C. 512(c)(3) for further details):
      </p>
      <ul>
        <li>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed;
        </li>

        <li>
          Identification of the copyrighted work claimed to have been
          infringed, or, if multiple copyrighted works at a single online site
          are covered by a single notification, a representative list of such
          works at that site (and registration information if such work(s) is
          registered with the Copyright Office);
        </li>

        <li>
          Identification of the material that is claimed to be infringing or
          to be the subject of infringing activity and that is to be removed or
          access to which is to be disabled and information reasonably
          sufficient to permit the service provider to locate the material;
        </li>

        <li>
          Information reasonably sufficient to permit the service provider to
          contact you, such as your name, address, telephone number, and email
          address;
        </li>

        <li>
          A statement that you have a good faith belief that use of the
          material in the manner complained of is not authorized by the
          copyright owner, its agent, or the law; and
        </li>

        <li>
          A statement that the information in the notification is accurate,
          and, under penalty of perjury, that you are authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed.
        </li>
      </ul>

      <p>
        (b) ShareMyAircraft.com's designated Copyright Agent to receive notifications
        of claimed infringement is E. Bradley Gould, who can be reached as follows:
      </p>
      <address>
        E. Bradley Gould<br>
        New York Office Managing Partner<br>
        Fox Rothschild LLP<br>
        101 Park Ave.<br>
        17th Floor<br>
        New York, NY 10178<br>
        <br>
        <a href="tel:2128787953">(212) 878-7953</a> - direct<br>
        <a href="tel:9148032671">(914) 803-2671</a> - mobile<br>
        <a href="mailto:egould@foxrothschild.com">egould@foxrothschild.com</a>
      </address>

      <p>
        For clarity, only DMCA notices should go to the Copyright Agent; any other feedback,
        comments, requests for technical support, and other communications should be directed
        to ShareMyAircraft.com customer service, at support@sharemyaircraft.com. You acknowledge
        that, if you fail to comply with all of the requirements of Section 10(a), your DMCA
        notice may not be valid.
      </p>

      <p>
        (c) Counter-Notice. If youeil believe that your Content that was removed
        (or to which access was disabled) is not infringing, or that you have
        the authorization from the copyright owner, the copyright owner's agent,
        or pursuant to the law, to post and use the material in your Content,
        you may send the Copyright Agent a counter-notice containing the
        following information:
      </p>
      <ul>
        <li>Your physical or electronic signature;</li>

        <li>
          Identification of the Content that has been removed or to which
          access has been disabled and the location at which the Content
          appeared before it was removed or disabled;
        </li>

        <li>
          A statement that you have a good faith belief that the Content was
          removed or disabled as a result of mistake or a misidentification of
          the Content; and
        </li>

        <li>
          Your name, address, telephone number, and email address, a statement
          that you consent to the jurisdiction of the federal court in New York,
          New York, and a statement that you will accept service of process from
          the person who provided notification of the alleged infringement.
        </li>
      </ul>
      <p>
        (d) If a counter-notice is received by the Copyright Agent,
        ShareMyAircraft.com will send a copy of the counter-notice to the
        original complaining party informing that person that it may replace the
        removed Content or cease disabling it in 10 business days. Unless the
        copyright owner files an action seeking a court order against the
        Content provider, member or user, the removed Content may be replaced,
        or access to it restored, in 10 to 14 business days after receipt of the
        counter-notice, in ShareMyAircraft.com's sole discretion.
      </p>

      <h2>11. Warranty Disclaimer</h2>

      <p>
        YOU AGREE THAT YOUR USE OF THE SERVICE WILL BE AT YOUR SOLE RISK. TO THE
        FULLEST EXTENT PERMITTED BY LAW, SHAREMYAIRCRAFT.COM, ITS OFFICERS,
        DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED, IN CONNECTION WITH THE SERVICE AND YOUR USE THEREOF.
        SHAREMYAIRCRAFT.COM MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE
        ACCURACY OR COMPLETENESS OF THIS SITE'S CONTENT OR THE CONTENT OF ANY
        SITES LINKED TO THIS SITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR
        ANY (I) ERRORS, MISTAKES, OR INACCURACIES IN ANY CONTENT, (II) PERSONAL
        INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
        ACCESS TO AND USE OF THE SERVICE, (III) ANY UNAUTHORIZED ACCESS TO OR
        USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
        FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION
        OF TRANSMISSION TO OR FROM THE SERVICE, (IV) ANY BUGS, VIRUSES, TROJAN
        HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICE
        BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR
        FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
        (OR INABILITY TO USE) ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
        OTHERWISE MADE AVAILABLE VIA THE SERVICE. SHAREMYAIRCRAFT.COM DOES NOT
        WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICE OR
        ANY HYPERLINKED SERVICES OR FEATURES OFFERED IN OR THROUGH ANY BANNER OR
        OTHER ADVERTISING. SHAREMYAIRCRAFT.COM WILL NOT BE A PARTY TO OR IN ANY
        WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
        USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </p>

      <h2>12. Limitation of Liability</h2>

      <p>
        (a) IN NO EVENT SHALL SHAREMYAIRCRAFT.COM, ITS OFFICERS, DIRECTORS,
        EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER
        RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES IN ANY CONTENT,
        (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
        RESULTING FROM YOUR ACCESS TO AND USE OF (OR INABILITY TO USE) THE
        SERVICE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
        AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
        STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
        FROM OUR SERVICES, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE,
        WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY,
        AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR
        DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF (OR INABILITY TO
        USE) ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
        AVAILABLE VIA THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR
        ANY OTHER LEGAL THEORY, AND WHETHER OR NOT SHAREMYAIRCRAFT.COM IS
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF
        LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE
        APPLICABLE JURISDICTION.
      </p>

      <p>
        (b) YOU SPECIFICALLY ACKNOWLEDGE THAT SHAREMYAIRCRAFT.COM SHALL NOT BE
        LIABLE FOR ANY CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT
        OF ANY THIRD PARTY, AND THAT THE RISK OF HARM OR DAMAGE FROM THE
        FOREGOING RESTS ENTIRELY WITH YOU.
      </p>

      <p>
        (c) The Service is controlled and offered by ShareMyAircraft.com from
        its facilities in the United States of America. ShareMyAircraft.com
        makes no representations that the Service is appropriate or available
        for use in other locations or jurisdictions. Those who access or use the
        Service from other jurisdictions do so at their own volition and are
        responsible for compliance with local law.
      </p>

      <h2>13. Indemnity</h2>

      <p>
        To the extent permitted by applicable law, you agree to defend,
        indemnify and hold harmless ShareMyAircraft.com, its parent corporation,
        officers, directors, employees and agents, from and against any and all
        claims, damages, obligations, losses, liabilities, costs, debts, and
        expenses (including but not limited to attorney's fees) arising from:
        (i) your use of and access to the Service; (ii) your violation of these
        Terms of Service; (iii) your violation of any third party right,
        including, but not limited to, any copyright, property, or privacy
        right; or (iv) any claim that your Content caused damage or injury to a
        third party. This defense and indemnification obligation will survive
        these Terms of Service and your use of the Service.
      </p>

      <h2>14. Ability to Accept Terms of Service</h2>

      <p>
        You affirm that you are either more than 18 years of age, or an
        emancipated minor, or possess legal parental or guardian consent, and
        are fully able and competent to enter into the terms, conditions,
        obligations, affirmations, representations, and warranties set forth in
        these Terms of Service, and to abide by and comply with these Terms of
        Service. In any case, you affirm that you are over the age of 13, as the
        Service is not intended for children under 13. If you are under 13 years
        of age, then please do not use the Service.
      </p>

      <h2>15. Availability</h2>

      <p>
        Content that ShareMyAircraft.com makes available on or through the
        Service may contain references or cross-references to products, programs
        or services of ShareMyAircraft.com, its partners, subsidiaries or
        affiliates, that are not necessarily announced or available in your
        area. These references do not mean that ShareMyAircraft.com, or any of
        its partners, subsidiaries or affiliates, will announce any of those
        products, programs or services in your area at any time in the future.
        You should contact ShareMyAircraft.com for information regarding the
        products, programs and services that may be available to you, if any.
      </p>

      <h2>16. Local Laws</h2>

      <p>
        ShareMyAircraft.com makes no representation that the Service, or any
        Content or information available on or through the Service, is
        appropriate or available for use in jurisdictions outside the United
        States. Access to the Service from jurisdictions where such access is
        illegal is prohibited. If you choose to access the Service from other
        jurisdictions, you do so on your own initiative and are responsible for
        compliance with applicable local laws. ShareMyAircraft.com is not
        responsible for any violation of law. You may not use the Service, or
        use or export any Content or information, in violation of U.S. export
        laws and regulations. You agree to comply with all applicable laws
        regarding the transmission of technical data exported from the United
        States and the country in which you reside (if different from the United
        States).
      </p>

      <h2>17. Assignment</h2>

      <p>
        Neither these Terms of Service, your account nor any rights or licenses
        granted hereunder may be transferred or assigned by you, but may be
        assigned by ShareMyAircraft.com without restriction.
      </p>

      <h2>18. General</h2>

      <p>
        You agree that: (i) the Service shall be deemed solely based in New
        York; and (ii) the Service shall be deemed a passive website that does
        not give rise to personal jurisdiction over ShareMyAircraft.com, either
        specific or general, in jurisdictions other than New York. These Terms
        of Service shall be governed by the internal substantive laws of the
        State of New York, without respect to its conflict of laws principles.
        Any claim or dispute between you and ShareMyAircraft.com that arises in
        whole or in part from the Service shall be decided exclusively by a
        court of competent jurisdiction located in New York City, New York.
        These Terms of Service, together with the Privacy Notice at
        http://www.ShareMyAircraft.com/privacy and any other legal notices
        published by ShareMyAircraft.com on the Service, shall constitute the
        entire agreement between you and ShareMyAircraft.com concerning the
        Service. If any provision of these Terms of Service is deemed invalid by
        a court of competent jurisdiction, the invalidity of such provision
        shall not affect the validity of the remaining provisions of these Terms
        of Service, which shall remain in full force and effect. No waiver of
        these Terms of Service shall be deemed a further or continuing waiver of
        such term or any other term, and ShareMyAircraft.com's failure to assert
        any right or provision under these Terms of Service shall not constitute
        a waiver of such right or provision. ShareMyAircraft.com reserves the
        right to amend these Terms of Service at any time and without notice,
        and it is your responsibility to review these Terms of Service for any
        changes. Your use of the Service following any amendment of these Terms
        of Service will signify your assent to and acceptance of the revised
        terms. YOU AND SHAREMYAIRCRAFT.COM AGREE THAT ANY CAUSE OF ACTION
        ARISING OUT OF OR RELATED TO THE SERVICE MUST COMMENCE WITHIN ONE (1)
        YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION
        IS PERMANENTLY BARRED.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmaTerms'
};
</script>

<style lang="scss" scoped>
</style>
